import * as Dialog from '@radix-ui/react-dialog'
import { signOut } from 'next-auth/react'
import { ExitSessionIcon } from '../../components/icons/exit-session'

export function CloseSession() {
  return (
    <Dialog.Root>
      <Dialog.Trigger className="-mx-3 mb-4 flex w-full items-center space-x-3 rounded-lg px-3 py-[10px] transition-all duration-200 ease-in-out hover:opacity-80">
        <div className="flex h-4 w-4 items-center justify-center rounded-full bg-[#AFAFAF]">
          <ExitSessionIcon />
        </div>
        <span className="text-sm font-semibold text-[#858585]">
          Cerrar sesión
        </span>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 grid place-items-center overflow-y-auto bg-black/40">
          <Dialog.Content className="relative min-w-[500px] rounded-2xl bg-white p-5">
            <Close />
            <div className="mb-16 mt-16 flex flex-col items-center justify-between gap-2">
              <h2 className="mb-4 text-lg font-semibold">
                ¿Estás seguro de cerrar sesión?
              </h2>
              <button
                className="h-10 w-60 rounded-md border bg-admin-blue px-7 text-white"
                onClick={() => signOut()}>
                Cerrar sesión
              </button>
              <Dialog.Close asChild>
                <button className="h-10 w-60 rounded-md border px-7">
                  Volver
                </button>
              </Dialog.Close>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

function Close() {
  return (
    <Dialog.Close className="absolute right-5 top-5">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.5 4.5L4.5 11.5"
          stroke="#737980"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 4.5L11.5 11.5"
          stroke="#737980"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Dialog.Close>
  )
}
