import clsx from 'clsx'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { Flag } from 'ui/icons/flag'

export function ImpugnmentRoute() {
  const localPathname = usePathname()
  const isRoute = localPathname.includes('/impugnaciones')

  return (
    <div className="relative flex cursor-pointer flex-col">
      <Link href="/impugnaciones">
        <div
          className={clsx(
            'z-[1] -mx-3 flex w-full items-center space-x-3 rounded-lg px-3 py-[10px] transition-all duration-200 ease-in-out hover:opacity-80',
            isRoute ? 'bg-admin-blue/[0.04]' : 'bg-transparent'
          )}>
          <Flag css={{ fill: isRoute ? '#0866F2' : '#AFAFAF', minWidth: 16 }} />
          <span
            className={clsx(
              'text-sm font-semibold',
              isRoute ? 'text-admin-blue' : 'text-[#858585]'
            )}>
            Impugnaciones
          </span>
        </div>
      </Link>
    </div>
  )
}
