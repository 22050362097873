import clsx from 'clsx'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import React from 'react'

export function LateralButton({
  pathname,
  children,
}: {
  children: React.ReactNode
  pathname: string
}) {
  const localPathname = usePathname()
  const isRoute = localPathname.includes(pathname)

  return (
    <div className="relative flex cursor-pointer flex-col">
      <Link href={pathname}>
        <div
          className={clsx(
            'z-[1] -ml-3 flex w-full items-center space-x-3 rounded-lg px-3 py-[10px] transition-all duration-200 ease-in-out hover:opacity-80',
            isRoute ? 'bg-admin-blue/[0.04]' : 'bg-transparent'
          )}>
          {children}
        </div>
      </Link>
    </div>
  )
}
