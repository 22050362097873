import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-dialog'
import * as Select from '@radix-ui/react-select'
import clsx from 'clsx'
import { useSession } from 'next-auth/react'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Button } from 'ui/components/button'
import { Field } from 'ui/components/input'
import { Switch } from 'ui/components/switch'
import { ChevronDown } from 'ui/icons/chevron-down'
import { ChevronUp } from 'ui/icons/chevron-up'
import { z } from 'zod'
import { titleToUrl } from '../..//services/api/course-class/title-to-url'
import { PlusCircle } from '../../components/icons/plus-circle'
import {
  selectedPlatformSelector,
  useSelectedPlatform,
} from '../../hooks/use-selected-platform'
import { useCreateExam } from '../../services/api/exams/use-create-exam'

const examSchema = z.object({
  name: z.string(),
  available: z.boolean(),
  examTimeLimit: z.number(),
  examTotalQuestions: z.number(),
  correctionValues: z.object({
    answersPerQuestion: z.number(),
    correctAnswerValue: z.number(),
    wrongAnswerValue: z.number(),
    emptyAnswerValue: z.number(),
  }),
  psychosAllowed: z.boolean(),
  psychosCorrectionValues: z
    .object({
      answersPerQuestion: z.number(),
      correctAnswerValue: z.number(),
      wrongAnswerValue: z.number(),
      emptyAnswerValue: z.number(),
    })
    .optional(),
  caseStudiesAllowed: z.boolean(),
  caseStudiesCorrectionValues: z
    .object({
      answersPerQuestion: z.number(),
      correctAnswerValue: z.number(),
      wrongAnswerValue: z.number(),
      emptyAnswerValue: z.number(),
    })
    .optional(),
  caseJourneyCreated: z.boolean(),
  caseJourneyValues: z
    .object({
      title: z.string(),
      status: z.boolean(),
      url: z.string(),
    })
    .optional(),
})

type ExamSchema = z.infer<typeof examSchema>

export function CreateExam() {
  const [opened, setOpened] = useState(false)

  const selectedPlatform = useSelectedPlatform(selectedPlatformSelector)
  const { handleCreateExam, loading } = useCreateExam()
  const { data: session } = useSession()

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<ExamSchema>({
    resolver: zodResolver(examSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      available: false,
      psychosAllowed: false,
      caseStudiesAllowed: false,
      caseJourneyCreated: false,
      caseJourneyValues: {
        title: '',
        status: false,
        url: '',
      },
    },
  })

  const handleFormSubmit: SubmitHandler<ExamSchema> = (formData) => {
    handleCreateExam({
      name: formData.name,
      available: formData.available,
      examTotalQuestions: Number(formData.examTotalQuestions),
      examTimeLimit: Number(formData.examTimeLimit),
      platformId: selectedPlatform?.id,
      correctionValues: {
        answersPerQuestion: Number(
          formData.correctionValues.answersPerQuestion
        ),
        correctAnswerValue: Number(
          formData.correctionValues.correctAnswerValue
        ),
        wrongAnswerValue: Number(formData.correctionValues.wrongAnswerValue),
        emptyAnswerValue: Number(formData.correctionValues.emptyAnswerValue),
      },
      psychosAllowed: formData.psychosAllowed,
      psychosCorrectionValues: formData.psychosCorrectionValues
        ? {
            answersPerQuestion: Number(
              formData.psychosCorrectionValues.answersPerQuestion
            ),
            correctAnswerValue: Number(
              formData.psychosCorrectionValues.correctAnswerValue
            ),
            wrongAnswerValue: Number(
              formData.psychosCorrectionValues.wrongAnswerValue
            ),
            emptyAnswerValue: Number(
              formData.psychosCorrectionValues.emptyAnswerValue
            ),
          }
        : null,
      caseStudiesAllowed: formData.caseStudiesAllowed,
      caseStudiesCorrectionValues: formData.caseStudiesCorrectionValues
        ? {
            answersPerQuestion: Number(
              formData.caseStudiesCorrectionValues.answersPerQuestion
            ),
            correctAnswerValue: Number(
              formData.caseStudiesCorrectionValues.correctAnswerValue
            ),
            wrongAnswerValue: Number(
              formData.caseStudiesCorrectionValues.wrongAnswerValue
            ),
            emptyAnswerValue: Number(
              formData.caseStudiesCorrectionValues.emptyAnswerValue
            ),
          }
        : null,
      caseJourneyCreated: formData.caseJourneyCreated,
      caseJourneyValues:
        formData.caseJourneyCreated && formData.caseJourneyValues
          ? {
              title: formData.caseJourneyValues.title,
              url: titleToUrl(formData.caseJourneyValues.title),
              owner: session.user?.id,
              status: caseJourneyStatus,
            }
          : null,
    }).then(() => setOpened(false))
  }

  useEffect(() => {
    reset()
  }, [reset, opened])

  const handleAvailableChange = (newValue) => {
    setValue('available', newValue === 'published')
  }

  const available = watch('available')
  const psychosAllowed = watch('psychosAllowed')
  const caseStudiesAllowed = watch('caseStudiesAllowed')
  const caseJourneyCreated = watch('caseJourneyCreated')
  const caseJourneyStatus = watch('caseJourneyValues.status')

  return (
    <Dialog.Root open={opened} onOpenChange={setOpened}>
      <Dialog.Trigger className="-mx-3 flex w-full items-center space-x-3 rounded-lg px-3 py-[10px] transition-all duration-200 ease-in-out hover:opacity-80">
        <PlusCircle className="h-[18px] w-[18px] min-w-[16px] fill-[#AFAFAF]" />
        <span className="text-sm font-semibold text-[#858585]">
          Crear oposición
        </span>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 grid place-items-center overflow-y-auto bg-black/40">
          <Dialog.Content className="relative my-8 min-w-[663px] rounded-2xl bg-white p-10 pb-5">
            <Close />
            <Dialog.Title className="text-lg text-[#1B2026]">
              Crear oposición
            </Dialog.Title>
            <p className="mb-8 text-body-small text-[#808080]">
              Rellena los campos para crear una oposición
            </p>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <fieldset className="mb-8">
                <label
                  htmlFor="nombre"
                  className="mb-3 flex text-body-small text-[#1B2026]">
                  Nombre
                </label>
                <Field name="nombre" register={() => register('name')} />
              </fieldset>
              <div className="mb-6 border-b border-[#E0E0E0] pb-6">
                <p className="mb-6 font-semibold ">Modo Test</p>
                <fieldset className="mb-6">
                  <label className="mb-3 flex text-body-small text-[#1B2026]">
                    Opciones de respuesta
                  </label>
                  <div className="h-10 w-full rounded-lg border px-2">
                    <select
                      className="h-full w-full"
                      {...register('correctionValues.answersPerQuestion', {
                        valueAsNumber: true,
                      })}>
                      <option value="3">3 opciones</option>
                      <option value="4">4 opciones</option>
                    </select>
                  </div>
                </fieldset>
                <fieldset>
                  <p className="mb-3 text-body-small">Valores de corrección</p>
                  <div className="grid grid-cols-3 gap-4">
                    <Field
                      register={() =>
                        register('correctionValues.correctAnswerValue', {
                          valueAsNumber: true,
                        })
                      }
                      label="Correctas"
                      name="correctAnswerValue"
                      placeholder="Ej. 1"
                    />
                    <Field
                      register={() =>
                        register('correctionValues.wrongAnswerValue', {
                          valueAsNumber: true,
                        })
                      }
                      label="Incorrectas"
                      name="wrongAnswerValue"
                      placeholder="Ej. -0.3"
                    />
                    <Field
                      register={() =>
                        register('correctionValues.emptyAnswerValue', {
                          valueAsNumber: true,
                        })
                      }
                      label="En blanco"
                      name="emptyAnswerValue"
                      placeholder="Ej. 0"
                    />
                  </div>
                </fieldset>
              </div>
              <div className="mb-6 border-b border-[#E0E0E0] pb-6">
                <p className="mb-6 font-semibold">Modo Simulacro</p>
                <fieldset>
                  <p className="mb-3 text-body-small">Valores de simulacro</p>
                  <div className="mb-4 grid grid-cols-3 gap-4">
                    <Field
                      register={() =>
                        register('examTimeLimit', {
                          valueAsNumber: true,
                        })
                      }
                      label="Tiempo límite"
                      name="limit"
                      placeholder="Límite en minutos"
                      type="number"
                    />
                    <Field
                      register={() =>
                        register('examTotalQuestions', {
                          valueAsNumber: true,
                        })
                      }
                      label="Nº de preguntas"
                      name="questions"
                      type="number"
                    />
                  </div>
                </fieldset>
              </div>

              <div className="mb-6 border-b border-[#E0E0E0] pb-6">
                <div className="flex items-center justify-between">
                  <p className="font-semibold">Modo Psicotécnico</p>
                  <Switch
                    checked={psychosAllowed}
                    defaultChecked={psychosAllowed}
                    onCheckedChange={() =>
                      setValue('psychosAllowed', !psychosAllowed)
                    }
                  />
                </div>
                {psychosAllowed && (
                  <div className="pt-6">
                    <fieldset className="mb-6">
                      <label className="mb-3 flex text-body-small text-[#1B2026]">
                        Opciones de respuesta
                      </label>
                      <div className="h-10 w-full rounded-lg border px-2">
                        <select
                          className="h-full w-full"
                          {...register(
                            'psychosCorrectionValues.answersPerQuestion',
                            {
                              valueAsNumber: true,
                            }
                          )}>
                          <option value="3">3 opciones</option>
                          <option value="4">4 opciones</option>
                        </select>
                      </div>
                    </fieldset>
                    <fieldset>
                      <p className="mb-3 text-body-small">
                        Valores de corrección
                      </p>
                      <div className="grid grid-cols-3 gap-4">
                        <Field
                          register={() =>
                            register(
                              'psychosCorrectionValues.correctAnswerValue',
                              {
                                valueAsNumber: true,
                              }
                            )
                          }
                          label="Correctas"
                          name="correctAnswerValue"
                          placeholder="Ej. 1"
                        />
                        <Field
                          register={() =>
                            register(
                              'psychosCorrectionValues.wrongAnswerValue',
                              {
                                valueAsNumber: true,
                              }
                            )
                          }
                          label="Incorrectas"
                          name="wrongAnswerValue"
                          placeholder="Ej. -0.3"
                        />
                        <Field
                          register={() =>
                            register(
                              'psychosCorrectionValues.emptyAnswerValue',
                              {
                                valueAsNumber: true,
                              }
                            )
                          }
                          label="En blanco"
                          name="emptyAnswerValue"
                          placeholder="Ej. 0"
                        />
                      </div>
                    </fieldset>
                  </div>
                )}
              </div>

              <div className="mb-6 border-b border-[#E0E0E0] pb-6">
                <div className="flex items-center justify-between">
                  <p className="font-semibold">Modo Supuesto Práctico</p>
                  <Switch
                    checked={caseStudiesAllowed}
                    defaultChecked={caseStudiesAllowed}
                    onCheckedChange={() =>
                      setValue('caseStudiesAllowed', !caseStudiesAllowed)
                    }
                  />
                </div>
                {caseStudiesAllowed && (
                  <div className="pt-6">
                    <fieldset className="mb-6">
                      <label className="mb-3 flex text-body-small text-[#1B2026]">
                        Opciones de respuesta
                      </label>
                      <div className="h-10 w-full rounded-lg border px-2">
                        <select
                          className="h-full w-full"
                          {...register(
                            'caseStudiesCorrectionValues.answersPerQuestion',
                            {
                              valueAsNumber: true,
                            }
                          )}>
                          <option value="3">3 opciones</option>
                          <option value="4">4 opciones</option>
                        </select>
                      </div>
                    </fieldset>
                    <fieldset>
                      <p className="mb-3 text-body-small">
                        Valores de corrección
                      </p>
                      <div className="grid grid-cols-3 gap-4">
                        <Field
                          register={() =>
                            register(
                              'caseStudiesCorrectionValues.correctAnswerValue',
                              {
                                valueAsNumber: true,
                              }
                            )
                          }
                          label="Correctas"
                          name="correctAnswerValue"
                          placeholder="Ej. 1"
                        />
                        <Field
                          register={() =>
                            register(
                              'caseStudiesCorrectionValues.wrongAnswerValue',
                              {
                                valueAsNumber: true,
                              }
                            )
                          }
                          label="Incorrectas"
                          name="wrongAnswerValue"
                          placeholder="Ej. -0.3"
                        />
                        <Field
                          register={() =>
                            register(
                              'caseStudiesCorrectionValues.emptyAnswerValue',
                              {
                                valueAsNumber: true,
                              }
                            )
                          }
                          label="En blanco"
                          name="emptyAnswerValue"
                          placeholder="Ej. 0"
                        />
                      </div>
                    </fieldset>
                  </div>
                )}
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <p className="font-semibold">Itinerario</p>
                  {!caseJourneyCreated ? (
                    <Button
                      onClick={(e) => {
                        setValue('caseJourneyCreated', true)
                        setValue('caseJourneyValues', {
                          title: '',
                          url: '',
                          status: false,
                        })
                        e.preventDefault()
                      }}>
                      Crear
                    </Button>
                  ) : (
                    <Switch
                      checked={caseJourneyStatus}
                      defaultChecked={caseJourneyStatus}
                      onCheckedChange={() =>
                        setValue('caseJourneyValues.status', !caseJourneyStatus)
                      }
                    />
                  )}
                </div>

                {caseJourneyCreated && (
                  <div className="pt-6">
                    <fieldset className="mb-8">
                      <label
                        htmlFor="title"
                        className="mb-3 flex text-body-small text-[#1B2026]">
                        Título
                      </label>
                      <Field
                        name="title"
                        register={() => register('caseJourneyValues.title')}
                      />
                    </fieldset>
                  </div>
                )}
              </div>

              <div className="-mx-10 mt-6 flex justify-end space-x-4 border-t px-5 pt-5">
                <Select.Root
                  defaultValue={available ? 'published' : 'draft'}
                  onValueChange={handleAvailableChange}>
                  <Select.Trigger
                    className="flex min-w-[182px] items-center justify-between rounded-md border border-black/10 bg-white px-4 py-3 leading-none text-black"
                    aria-label="Status">
                    <Select.Value />

                    <Select.Icon>
                      <ChevronDown />
                    </Select.Icon>
                  </Select.Trigger>
                  <Select.Portal>
                    <Select.Content className="overflow-hidden rounded-md border bg-white">
                      <Select.ScrollUpButton className="text-violet11 flex h-[25px] cursor-default items-center justify-center bg-white">
                        <ChevronUp />
                      </Select.ScrollUpButton>
                      <Select.Viewport className="space-y-2 p-4">
                        <SelectItem value="published">
                          <div className="flex items-center">
                            <div className="relative mr-2 grid h-4 w-4 place-items-center">
                              <div className="h-[8px] min-h-[8px] w-[8px] min-w-[8px] rounded-full bg-[#4BBE5E]" />
                              <div className="absolute h-full w-full rounded-full border-2 border-[#4BBE5E]" />
                            </div>
                            <span>Publicado</span>
                          </div>
                        </SelectItem>
                        <SelectItem value="draft">
                          <div className="flex items-center">
                            <div className="relative mr-2 grid h-4 w-4 place-items-center">
                              <div className="h-[8px] min-h-[8px] w-[8px] min-w-[8px] rounded-full bg-[#7B7B7B]" />
                              <div className="absolute h-full w-full rounded-full border-2 border-[#7B7B7B]" />
                            </div>
                            <span>Borrador</span>
                          </div>
                        </SelectItem>
                      </Select.Viewport>
                      <Select.ScrollDownButton className="text-violet11 flex h-[25px] cursor-default items-center justify-center bg-white">
                        <ChevronDown />
                      </Select.ScrollDownButton>
                    </Select.Content>
                  </Select.Portal>
                </Select.Root>
                <button
                  disabled={loading}
                  className="flex items-center rounded-md bg-admin-blue px-4 py-3 leading-none text-white disabled:cursor-not-allowed disabled:opacity-50">
                  Guardar
                </button>
              </div>
            </form>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

function SelectItem({ children, className, ...props }: any) {
  return (
    <Select.Item {...props}>
      <Select.ItemText className={clsx('flex items-center', className)}>
        {children}
      </Select.ItemText>
    </Select.Item>
  )
}

function Close() {
  return (
    <Dialog.Close className="absolute right-5 top-5">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.5 4.5L4.5 11.5"
          stroke="#737980"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 4.5L11.5 11.5"
          stroke="#737980"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Dialog.Close>
  )
}
