import { SVGProps } from 'react'
import * as ReactIcons from 'react-icons/io5'

export type IconTypes = keyof typeof ReactIcons

export interface IconProviderProps extends SVGProps<SVGElement> {
  title: IconTypes
}

export function IconProvider({
  title = 'IoLogoNodejs',
  width = 40,
  height = 40,
  ...props
}: IconProviderProps) {
  const Icon = ReactIcons[title]
  return Icon ? <Icon style={{ width, height }} {...props} /> : null
}
