'use client'
export function VideoCamera(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}>
      <path
        d="M18.125 15.0152C17.95 15.0151 17.777 14.9782 17.6172 14.907C17.58 14.8905 17.5445 14.8704 17.5113 14.8469L14.2805 12.5727C14.1166 12.4573 13.9829 12.3043 13.8906 12.1264C13.7983 11.9486 13.75 11.7512 13.75 11.5508V8.44922C13.75 8.24884 13.7983 8.05141 13.8906 7.87357C13.9829 7.69573 14.1166 7.54269 14.2805 7.42734L17.5113 5.15312C17.5445 5.12963 17.58 5.10948 17.6172 5.09297C17.8075 5.00836 18.0159 4.97264 18.2236 4.98906C18.4312 5.00549 18.6314 5.07353 18.8061 5.18701C18.9807 5.30049 19.1242 5.45581 19.2236 5.63886C19.323 5.82191 19.375 6.02688 19.375 6.23516V13.7648C19.375 14.0964 19.2433 14.4143 19.0089 14.6487C18.7745 14.8831 18.4565 15.0148 18.125 15.0148V15.0152ZM10.4688 15.625H3.28125C2.57699 15.6243 1.90178 15.3442 1.4038 14.8462C0.90581 14.3482 0.625724 13.673 0.625 12.9688V7.03125C0.625724 6.32699 0.90581 5.65178 1.4038 5.1538C1.90178 4.65581 2.57699 4.37572 3.28125 4.375H10.4875C11.1868 4.37583 11.8571 4.65397 12.3516 5.14842C12.846 5.64287 13.1242 6.31325 13.125 7.0125V12.9688C13.1243 13.673 12.8442 14.3482 12.3462 14.8462C11.8482 15.3442 11.173 15.6243 10.4688 15.625Z"
        fill="parent"
      />
    </svg>
  )
}
