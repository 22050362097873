export function CaseStudy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="#0866F2"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M6 1.5C4.34315 1.5 3 2.84315 3 4.5V13.5C3 15.1569 4.34315 16.5 6 16.5H8.85718C8.0166 15.6241 7.5 14.4349 7.5 13.125C7.5 10.4326 9.68261 8.25 12.375 8.25C13.3415 8.25 14.2423 8.53125 15 9.01636V4.5C15 2.84315 13.6569 1.5 12 1.5H6Z"
        fill="parent"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.375 9.75C10.511 9.75 9 11.261 9 13.125C9 14.989 10.511 16.5 12.375 16.5C13.0408 16.5 13.6622 16.3067 14.1852 15.9738L14.836 16.6465C15.124 16.9442 15.5988 16.952 15.8965 16.664C16.1942 16.376 16.202 15.9012 15.914 15.6035L15.241 14.9079C15.5634 14.3906 15.75 13.7792 15.75 13.125C15.75 11.261 14.239 9.75 12.375 9.75ZM10.5 13.125C10.5 12.0895 11.3395 11.25 12.375 11.25C13.4105 11.25 14.25 12.0895 14.25 13.125C14.25 13.6361 14.0465 14.0982 13.7143 14.4372C13.3731 14.7854 12.8998 15 12.375 15C11.3395 15 10.5 14.1605 10.5 13.125Z"
        fill="parent"
      />
    </svg>
  )
}
