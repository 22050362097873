import clsx from 'clsx'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { Book } from 'ui/icons/book'

export function ThemesRoute() {
  const localPathname = usePathname()
  const isRoute =
    (localPathname.includes('/test') &&
      !localPathname.includes('/oposicion')) ||
    localPathname.includes('/test/preguntas')

  return (
    <div className="relative flex cursor-pointer flex-col">
      <Link href="/test">
        <div
          className={clsx(
            'z-[1] -mx-3 flex w-full items-center space-x-3 rounded-lg px-3 py-[10px] transition-all duration-200 ease-in-out hover:opacity-80',
            isRoute ? 'bg-admin-blue/[0.04]' : 'bg-transparent'
          )}>
          <Book
            className={clsx(
              'min-w-[16px]',
              isRoute ? 'fill-admin-blue' : 'fill-[#AFAFAF]'
            )}
          />
          <span
            className={clsx(
              'text-sm font-semibold',
              isRoute ? 'text-admin-blue' : 'text-[#858585]'
            )}>
            Test
          </span>
        </div>
      </Link>
    </div>
  )
}
