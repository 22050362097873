import { Flex } from 'ui/components/flex'
import { Placeholder } from 'ui/components/placeholder'

export function Empty() {
  return (
    <Flex
      css={{
        height: 26,
        width: 166,
        spacingX: '12px',
        alignItems: 'center',
        marginBottom: 6,
      }}>
      <Placeholder css={{ borderRadius: '100%', square: 18 }} />
      <Placeholder />
    </Flex>
  )
}
