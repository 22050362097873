import { useEffect, useState } from 'react'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

// Selectors
export const userSelector = (state) => state.user
export const setUserSelector = (state) => state.setUser
export const clearStateSelector = (state) => state.clearState

type UserAuthState = {
  user: any
  setUser: (user: any) => void
  clearState: () => void
}

// Hook
export const useAuthStore = create<UserAuthState>()(
  persist(
    (set) => ({
      user: null,
      setUser: (user) => set({ user }),
      clearState: () => set({ user: null }),
    }),
    {
      name: 'auth-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
)

export const useAuth = () => {
  const [state, setState] = useState(null)
  const user = useAuthStore(userSelector)

  useEffect(() => {
    setState(user)
  }, [user])

  return state
}
