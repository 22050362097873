export function CalendarFilledSvg(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="#AFAFAF"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M6 1.5C6.41421 1.5 6.75 1.83579 6.75 2.25V3H11.25V2.25C11.25 1.83579 11.5858 1.5 12 1.5C12.4142 1.5 12.75 1.83579 12.75 2.25V3C14.4069 3 15.75 4.34315 15.75 6V6.75H2.25V6C2.25 4.34315 3.59315 3 5.25 3V2.25C5.25 1.83579 5.58579 1.5 6 1.5Z"
        fill="parent"
      />
      <path
        d="M2.25 8.25V12.75C2.25 14.4069 3.59315 15.75 5.25 15.75H12.75C14.4069 15.75 15.75 14.4069 15.75 12.75V8.25H2.25Z"
        fill="parent"
      />
    </svg>
  )
}
