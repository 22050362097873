import useSWR from 'swr'
import { useAdminToken } from 'utils/fetcher/admin-fetcher'
import { getExamsByPlatform } from './get-exams-by-platform'

export function useExamsByPlatform(platformId: number) {
  const token = useAdminToken((state) => state.token)
  return useSWR(
    platformId && token ? ['exam-by-platform', { token, platformId }] : null,
    ([_, { platformId }]) => getExamsByPlatform({ platformId })
  )
}
