'use client'
import { ENVIROMENT } from '../../../config/constants'

export function EnvironmentAlert() {
  return ENVIROMENT !== 'production' ? (
    <div
      className="flex h-[28px] min-h-[28px] w-full"
      style={{
        backgroundImage:
          'repeating-linear-gradient(-45deg, #FBDFDF, #FBDFDF 8px, #FACACC 8px, #FACACC 10px )',
      }}>
      <p className="m-1.5 rounded bg-[#C46064] px-2 text-xs font-bold text-white">
        {ENVIROMENT.toUpperCase()} BACKOFFICE
      </p>
    </div>
  ) : null
}
