export const getExamsByPlatformQuery = /* GraphQL */ `
  query getExamsByPlatformAdminQuery(
    $getExamsByPlatformInput: GetExamsByPlatformInput!
  ) {
    getExamsByPlatform(getExamsByPlatformInput: $getExamsByPlatformInput) {
      questionsAmount
      id
      name
      examTotalQuestions
      examTimeLimit
      answersPerQuestion
      available
    }
  }
`
