import { Bombero } from '@/components/icons/bombero'
import { Correo } from '@/components/icons/correos'
import { Policia } from '@/components/icons/policia'
import { Administrativo } from '@/components/icons/administrativo'
import { Memoria } from '@/components/icons/memoria'
import { FN } from '@/components/icons/formacion'

export const platformsIcons = {
  bombero: Bombero,
  policia: Policia,
  correos: Correo,
  administrativo: Administrativo,
  memoria: Memoria,
  ninja: FN,
  'mir/eir': FN,
  'constitucion-espanola': FN,
}
