import { clsx } from 'clsx'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { Person } from '../../../components/icons/person'

export function UsersRoute() {
  const localPathname = usePathname()
  const isRoute = localPathname.includes('/usuarios')

  return (
    <div className="relative flex cursor-pointer flex-col">
      <Link href="/usuarios">
        <div
          className={clsx(
            'z-[1] -mx-3 flex w-full items-center space-x-3 rounded-lg px-3 py-[10px] transition-all duration-200 ease-in-out hover:opacity-80',
            isRoute ? 'bg-admin-blue/[0.04]' : 'bg-transparent'
          )}>
          <Person
            className={clsx(
              'min-w-[16px]',
              isRoute ? 'fill-admin-blue' : 'fill-[#AFAFAF]'
            )}
          />
          <span
            className={clsx(
              'text-sm font-semibold',
              isRoute ? 'text-admin-blue' : 'text-[#858585]'
            )}>
            Usuarios
          </span>
        </div>
      </Link>
    </div>
  )
}
