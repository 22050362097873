import { SVGProps } from 'react'

export function PlusCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66602 10.0001C1.66602 5.39771 5.39698 1.66675 9.99935 1.66675C14.6017 1.66675 18.3327 5.39771 18.3327 10.0001C18.3327 14.6025 14.6017 18.3334 9.99935 18.3334C5.39698 18.3334 1.66602 14.6025 1.66602 10.0001ZM13.3327 10.8333C13.793 10.8333 14.166 10.4602 14.166 9.99997C14.166 9.53973 13.7929 9.16665 13.3327 9.16666L10.8327 9.16673V6.66685C10.8327 6.20661 10.4596 5.83352 9.99935 5.83352C9.53911 5.83352 9.16602 6.20661 9.16602 6.66685V9.16678L6.66599 9.16685C6.20575 9.16686 5.83267 9.53997 5.83268 10.0002C5.8327 10.4604 6.2058 10.8335 6.66604 10.8335L9.16602 10.8334V13.3334C9.16602 13.7937 9.53911 14.1667 9.99935 14.1667C10.4596 14.1667 10.8327 13.7937 10.8327 13.3334V10.8334L13.3327 10.8333Z"
        fill="parentColor"
      />
    </svg>
  )
}
