import * as SelectPrimitive from '@radix-ui/react-select'
import { useMemo } from 'react'
import { Text } from 'ui/components/text'
import { styled } from 'ui/config'
import { Policia } from '../../../components/icons/policia'
import { platformsIcons } from '../../../utils/platform-icons'

export function SelectItem({ item, disabled }) {
  const Icon = useMemo(
    () => platformsIcons[item.label.toLowerCase()] || Policia,
    [item.label]
  )

  return (
    <StyledItem value={item.value} disabled={disabled} key={item.label}>
      <SelectPrimitive.ItemText>
        <div className="flex items-center">
          <Icon css={{ width: 16 }} />
          <Text
            css={{
              lineHeight: 1,
              fontSize: 14,
              marginLeft: 12,
              fontWeight: 600,
            }}>
            {item.label}
          </Text>
        </div>
      </SelectPrimitive.ItemText>
    </StyledItem>
  )
}

const StyledItem = styled(SelectPrimitive.Item, {
  all: 'unset',
  lineHeight: 1,
  color: '$adminGrey500',
  borderRadius: 3,
  display: 'flex',
  alignItems: 'center',
  height: 35,
  padding: '0 0 0 25px',
  position: 'relative',
  userSelect: 'none',

  '&[data-disabled]': {
    color: '$adminGrey400',
    pointerEvents: 'none',
  },

  '&:focus': {
    backgroundColor: '$adminGrey100',
    color: '$adminGrey500',
  },
})
