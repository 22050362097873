import { httpLink } from '@trpc/client'
import { createTRPCNext } from '@trpc/next'
import type { AppRouter } from '@/pages/api/trpc/[trpc]'
import superjson from 'superjson'

function getBaseUrl() {
  if (typeof window !== 'undefined') {
    // In the browser, we return a relative URL
    return ''
  }

  // When rendering on the server, we return an absolute URL

  // reference for vercel.com
  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`
  }

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`
}

export const trpc = createTRPCNext<AppRouter>({
  config() {
    const url = getBaseUrl()
    return {
      links: [
        httpLink({
          url: getBaseUrl() + '/api/trpc',
        }),
      ],
      transformer: superjson,
    }
  },
  ssr: true,
})

const enum ROLES {
  MANAGER = 'MANAGER',
  PROFESSOR = 'PROFESSOR',
  EDITOR = 'EDITOR',
}

export const GET_ROLE = (): 'MANAGER' | 'PROFESSOR' | 'EDITOR' => {
  if (WHO_AM_I === MANAGER) return ROLES.MANAGER
  if (WHO_AM_I === PROFESSOR) return ROLES.PROFESSOR
  if (WHO_AM_I === EDITOR) return ROLES.EDITOR
  return ROLES.EDITOR
} //TODO: REMOVE THIS!!! (MANAGER)

const MANAGER: number = 13 // (Jorge) TODO: REMOVE THIS!!!
const PROFESSOR: number = 15 // (Rocío García) TODO: REMOVE THIS!!!
const EDITOR: number = 14 // (Benjamin) TODO: REMOVE THIS!!!
export const WHO_AM_I = MANAGER //TODO: REMOVE THIS!!! (MANAGER)
