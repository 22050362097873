import { Session } from 'next-auth'
import { useSession } from 'next-auth/react'
import { UserPermissionsType } from 'types/common/roles'

export function withPermission(
  session: Session,
  permission: UserPermissionsType
) {
  return (session?.user?.permissions || []).includes(permission)
}
export function withAnyPermission(
  session: Session,
  permissions: UserPermissionsType[]
) {
  return permissions.some((permission) => withPermission(session, permission))
}

export function usePermissions() {
  const { data: session } = useSession()

  const hasPermission = (permission: UserPermissionsType) => {
    return withPermission(session, permission)
  }

  const hasAnyPermission = (permissions: UserPermissionsType[]) => {
    return withAnyPermission(session, permissions)
  }

  return {
    hasPermission,
    hasAnyPermission,
  }
}
