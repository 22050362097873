import * as Dialog from '@radix-ui/react-dialog'

import { useMemo } from 'react'
import { getExamIcon } from 'ui/icons/get-exam-icon'
import {
  selectedPlatformSelector,
  useSelectedPlatform,
} from '../../hooks/use-selected-platform'
import { useExamsByPlatform } from '../../services/api/exams/use-get-exams-by-platform'
import { useGetPlatforms } from '../../services/api/platforms/use-get-platforms'

export function Stats() {
  const selectedPlatform = useSelectedPlatform(selectedPlatformSelector)

  const { data } = useGetPlatforms()
  const { data: examsRaw } = useExamsByPlatform(
    selectedPlatform?.id || data?.[0]?.id
  )
  const exams = useMemo(() => {
    return examsRaw
      ?.map((a) => a)
      .sort((a, b) => b.questionsAmount - a.questionsAmount)
  }, [examsRaw])

  const max = useMemo(() => {
    const maxNumber = exams
      ? Math.max(...exams?.map((exam) => exam.questionsAmount || 0))
      : 0
    return maxNumber
  }, [exams])

  return (
    <Dialog.Root>
      <Dialog.Trigger className="-mx-3 flex w-full items-center space-x-3 rounded-lg px-3 py-[10px] transition-all duration-200 ease-in-out hover:opacity-80">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          className="h-[18px] w-[18px] min-w-[16px]"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.32379 12.5508C2.21521 12.5508 2.10893 12.5195 2.01779 12.4604C1.92665 12.4014 1.85454 12.3173 1.81016 12.2182C1.27313 11.0188 1.04478 9.70415 1.14592 8.39394C1.24705 7.08374 1.67445 5.81967 2.38917 4.71692C3.10389 3.61418 4.0832 2.70782 5.23787 2.08044C6.39254 1.45306 7.68586 1.1246 8.99996 1.125C9.14914 1.125 9.29222 1.18426 9.39771 1.28975C9.5032 1.39524 9.56246 1.53832 9.56246 1.6875V9C9.56248 9.10862 9.53105 9.21492 9.47198 9.30607C9.4129 9.39721 9.32869 9.4693 9.22953 9.51363L2.55336 12.5002C2.48128 12.5331 2.40303 12.5503 2.32379 12.5508Z"
            fill="#AFAFAF"
          />
          <path
            d="M11.0246 2.39697C10.9838 2.3887 10.9417 2.38958 10.9013 2.39955C10.8609 2.40953 10.8232 2.42834 10.791 2.45464C10.7587 2.48094 10.7327 2.51407 10.7148 2.55163C10.6969 2.5892 10.6876 2.63028 10.6875 2.67189V9.00002C10.6872 9.3257 10.5928 9.64437 10.4157 9.91768C10.2386 10.191 9.98622 10.4073 9.68905 10.5406L3.62565 13.2539C3.58806 13.2707 3.55462 13.2955 3.52768 13.3266C3.50074 13.3578 3.48095 13.3944 3.46972 13.434C3.45849 13.4736 3.45608 13.5152 3.46268 13.5558C3.46927 13.5964 3.4847 13.6351 3.50787 13.6691C3.97191 14.3516 4.5479 14.9508 5.21155 15.4413C6.46891 16.3767 7.99538 16.8797 9.56248 16.875C13.5946 16.875 16.875 13.5946 16.875 9.56252C16.875 6.03107 14.3585 3.07619 11.0246 2.39697Z"
            fill="#AFAFAF"
          />
        </svg>
        <span className="text-sm font-semibold text-[#858585]">
          Estadísticas
        </span>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 grid place-items-center overflow-y-auto bg-black/40">
          <Dialog.Content className="relative my-5 min-w-[663px] rounded-2xl bg-white p-10 pb-5">
            <Close />
            <Dialog.Title className="text-lg text-[#1B2026]">
              Estadísticas
            </Dialog.Title>
            <h3 className="text-body-small text-[#808080]">
              Número de preguntas por oposición
            </h3>
            <div className="mb-14 mt-8 flex flex-col space-y-6">
              {exams?.map((exam, index) => {
                const Icon = getExamIcon(exam.name)
                return (
                  <div
                    key={exam.id}
                    className="grid grid-cols-[1fr_135px_100px] items-center gap-2">
                    <div className="flex items-center space-x-2">
                      <Icon active css={{ square: 20 }} />
                      <span>{exam.name}</span>
                    </div>
                    <div
                      className="h-2 rounded-full bg-[#0866F2]"
                      style={{
                        width:
                          index === 0
                            ? '100%'
                            : `${(exam.questionsAmount / max) * 100}%`,
                      }}></div>
                    <div className="text-right text-[#858585]">
                      {exam.questionsAmount}
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="-mx-10 -mb-1 mt-5 flex justify-end border-t px-4 pt-4">
              <Dialog.Close asChild>
                <button className="h-10 rounded-md border px-7">Cerrar</button>
              </Dialog.Close>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

function Close() {
  return (
    <Dialog.Close className="absolute right-5 top-5">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.5 4.5L4.5 11.5"
          stroke="#737980"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 4.5L11.5 11.5"
          stroke="#737980"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Dialog.Close>
  )
}
