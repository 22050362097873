import { styled } from 'ui'

function Svg(props) {
  return (
    <svg
      id="Capa_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48.79 48.79"
      {...props}>
      <defs>
        <style>{'.cls-1{fill:#102951;}.cls-2{fill:#ffda00;}'}</style>
      </defs>
      <g id="Capa_1-2">
        <circle className="cls-2" cx="24.39" cy="24.39" r="24.39" />
        <circle className="cls-2" cx="24.39" cy="24.39" r="24.39" />
        <path
          className="cls-1"
          d="m41.65,33.74h-6.03c0-4.59-1.35-8.04-4.14-10.54-4.43-3.98-11.43-4.57-15.24-4.58v15.12h-6.03V13.04l2.68-.3c.58-.07,14.26-1.51,22.59,5.95,4.09,3.66,6.17,8.72,6.17,15.05Z"
        />
      </g>
    </svg>
  )
}

export const Correo = styled(Svg)
