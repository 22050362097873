import { styled } from 'ui'

function Svg(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
        fill="#FFCD00"
      />
      <path
        d="M15.3649 12.4468H13.1393C13.1393 10.7579 12.6383 9.47999 11.6118 8.5586C9.97542 7.09113 7.39514 6.86969 5.98209 6.86969V12.4468H3.76587V4.8111L4.75294 4.70038C4.96687 4.67599 10.0073 4.13741 13.0886 6.89409C14.5993 8.24334 15.3649 10.1124 15.3649 12.4468Z"
        fill="#fff"
      />
    </svg>
  )
}

export const Memoria = styled(Svg)
