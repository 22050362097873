export enum UserPermissionsType {
  COMMENTS_TRANSFER = 'COMMENTS_TRANSFER',
  COMMENTS_REPLY = 'COMMENTS_REPLY',
  COMMENTS_EDIT = 'COMMENTS_EDIT',
  COMMENTS_DELETE = 'COMMENTS_DELETE',
  COMMENTS_ADD_LINK = 'COMMENTS_ADD_LINK',
  AVATARS_SELECT_ALL_ICONS = 'AVATARS_SELECT_ALL_ICONS',
  UNLOCK_ALL_COURSE_CLASS = 'UNLOCK_ALL_COURSE_CLASS',
  BACKOFFICE_ACCESS = 'BACKOFFICE_ACCESS',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  IMPUGMENTS_MANAGEMENT = 'IMPUGMENTS_MANAGEMENT',
  TEST_MANAGEMENT = 'TEST_MANAGEMENT',
  PSYCHOS_MANAGEMENT = 'PSYCHOS_MANAGEMENT',
  CASE_STUDY_MANAGEMENT = 'CASE_STUDY_MANAGEMENT',
  SIMULACRUM_MANAGEMENT = 'SIMULACRUM_MANAGEMENT',
  EXAM_MANAGEMENT = 'EXAM_MANAGEMENT',
  COURSE_CLASS_MANAGEMENT = 'COURSE_CLASS_MANAGEMENT',
  VIEW_ALL_PLATFORMS_AND_OPOSITIONS = 'VIEW_ALL_PLATFORMS_AND_OPOSITIONS',
  IS_ELITE_USER = 'IS_ELITE_USER',
  NOTIFICATIONS_ACCESS = 'NOTIFICATIONS_ACCESS',
  NOTIFICATIONS_OPTIONS_IN_APP = 'NOTIFICATIONS_OPTIONS_IN_APP',
  NOTIFICATIONS_OPTIONS_MODAL = 'NOTIFICATIONS_OPTIONS_MODAL',
  NOTIFICATIONS_OPTIONS_TOPBAR = 'NOTIFICATIONS_OPTIONS_TOPBAR',
  NOTIFICATIONS_OPTIONS_EMAIL = 'NOTIFICATIONS_OPTIONS_EMAIL',
  NOTIFICATIONS_OPTIONS_PUSH = 'NOTIFICATIONS_OPTIONS_PUSH',
}

export enum UserRolesType {
  MANAGER = 'MANAGER',
  PROFESSOR = 'PROFESSOR',
  EDITOR = 'EDITOR',
  OFFICE = 'OFFICE',
  SUPPORT = 'SUPPORT',
  ELITE = 'ELITE',
  TEST_WRITER = 'TEST_WRITER',
  PSICO_WRITER = 'PSICO_WRITER',
}
