import { useRouter } from 'next/navigation'
import { useState } from 'react'
import { useSWRConfig } from 'swr'
import { CreateExamInput } from 'types/admin'
import { slugify, useAdminToken } from 'utils'
import { useGetPlatforms } from '../platforms/use-get-platforms'
import { createExam } from './create-exam'

export function useCreateExam() {
  const router = useRouter()
  const { mutate } = useSWRConfig()
  const { data: platforms } = useGetPlatforms()
  const [loading, setLoading] = useState(false)
  const token = useAdminToken((state) => state.token)

  const handleCreateExam = async (data: CreateExamInput) => {
    try {
      setLoading(true)
      const createdExam = await createExam(data)
      mutate(['exam-by-platform', { token, platformId: platforms?.[0]?.id }])

      router.push(
        `/oposicion/${createdExam.id}-${slugify(createdExam.name)}/test`
      )

      return createdExam
    } catch (e) {
      console.log('CreateExam error:', e)
    } finally {
      setLoading(false)
    }
  }

  return { loading, handleCreateExam }
}
