export function Person(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="#AFAFAF"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M9 1.5C7.13604 1.5 5.625 3.01104 5.625 4.875C5.625 6.73896 7.13604 8.25 9 8.25C10.864 8.25 12.375 6.73896 12.375 4.875C12.375 3.01104 10.864 1.5 9 1.5Z"
        fill="parent"
      />
      <path
        d="M9.00008 9C6.48178 9 4.47197 10.3246 3.41452 12.2914C2.9426 13.1692 3.12047 14.0802 3.64363 14.7311C4.14861 15.3593 4.96782 15.75 5.84652 15.75H12.1536C13.0323 15.75 13.8516 15.3593 14.3565 14.7311C14.8797 14.0802 15.0576 13.1692 14.5856 12.2914C13.5282 10.3246 11.5184 9 9.00008 9Z"
        fill="parent"
      />
    </svg>
  )
}
