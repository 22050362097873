import { Layout } from '@/components/layout'
import { MAX_TIME_TOKEN_VERIFICATION } from '@/config/constants'
import { UserLoginConfig } from '@/services/auth/user-login-config.component'
import { trpc } from '@/utils/trpc'
import { SessionProvider } from 'next-auth/react'
import { Toaster } from 'sonner'
import { SWRConfig } from 'swr'
import { swrConfig } from 'utils'
import '../global.css'

function MyApp({ Component, pageProps }) {
  return (
    <SessionProvider
      session={pageProps.session}
      refetchInterval={MAX_TIME_TOKEN_VERIFICATION}
      refetchOnWindowFocus={true}>
      <UserLoginConfig />
      <SWRConfig value={swrConfig}>
        {Component.publicLayout ? (
          <Component {...pageProps} />
        ) : (
          <Layout>
            <Component {...pageProps} />
          </Layout>
        )}
        <Toaster richColors position="top-center" />
      </SWRConfig>
    </SessionProvider>
  )
}

export default trpc.withTRPC(MyApp)
