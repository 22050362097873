export function Psychotechnical(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="#EE4562"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M7 3C7.55228 3 8 3.44772 8 4V6H10C10.5523 6 11 6.44772 11 7C11 7.55228 10.5523 8 10 8H8V10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10V8H4C3.44772 8 3 7.55228 3 7C3 6.44772 3.44772 6 4 6H6V4C6 3.44772 6.44772 3 7 3Z"
        fill="parent"
      />
      <path
        d="M17 3C14.7909 3 13 4.79086 13 7C13 9.20914 14.7909 11 17 11C19.2091 11 21 9.20914 21 7C21 4.79086 19.2091 3 17 3Z"
        fill="parent"
      />
      <path
        d="M19.8284 15.5854C20.219 15.1949 20.219 14.5617 19.8284 14.1712C19.4379 13.7807 18.8047 13.7807 18.4142 14.1712L17 15.5854L15.5858 14.1712C15.1953 13.7807 14.5621 13.7807 14.1716 14.1712C13.781 14.5617 13.781 15.1949 14.1716 15.5854L15.5858 16.9996L14.1716 18.4138C13.781 18.8044 13.781 19.4375 14.1716 19.8281C14.5621 20.2186 15.1953 20.2186 15.5858 19.8281L17 18.4138L18.4142 19.8281C18.8047 20.2186 19.4379 20.2186 19.8284 19.8281C20.219 19.4375 20.219 18.8044 19.8284 18.4138L18.4142 16.9996L19.8284 15.5854Z"
        fill="parent"
      />
      <path
        d="M6 13C4.34315 13 3 14.3431 3 16V18C3 19.6569 4.34315 21 6 21H8C9.65685 21 11 19.6569 11 18V16C11 14.3431 9.65685 13 8 13H6Z"
        fill="parent"
      />
    </svg>
  )
}
