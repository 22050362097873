import { Platform } from 'types/admin'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export const useSelectedPlatform = create<{
  selected: Platform
  setSelected: (data: Platform) => void
}>()(
  persist(
    (set: (data) => void) => ({
      selected: null,
      setSelected: (selected) => set({ selected }),
    }),
    {
      name: 'selectedPlatform',
      storage: createJSONStorage(() => localStorage),
    }
  )
)

export const selectedPlatformSelector = (state: any) => state.selected
export const setSelectedPlatformSelector = (state: any) => state.setSelected
